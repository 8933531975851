body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

html,
body,
#root {
  background-color: #F4F5F9;
  background-color: $body-bg;
  width: 100%;
  height: 100%;
  min-height: 100%;
  //overflow: auto;
  //background-image: url('/images/galaxia5.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

@font-face {
  font-family: 'ShellHeavy';
  src: url('/fonts/ShellHeavy.eot');
  src: url('/fonts/ShellHeavy.eot') format('embedded-opentype'),
       url('/fonts/ShellHeavy.woff2') format('woff2'),
       url('/fonts/ShellHeavy.woff') format('woff'),
       url('/fonts/ShellHeavy.ttf') format('truetype'),
       url('/fonts/ShellHeavy.svg#ShellHeavy') format('svg');
}
@font-face {
  font-family: 'ShellBook';
  src: url('/fonts/ShellBook.eot');
  src: url('/fonts/ShellBook.eot') format('embedded-opentype'),
       url('/fonts/ShellBook.woff2') format('woff2'),
       url('/fonts/ShellBook.woff') format('woff'),
       url('/fonts/ShellBook.ttf') format('truetype'),
       url('/fonts/ShellBook.svg#ShellBook') format('svg');
}
@font-face {
  font-family: 'ShellBold';
  src: url('/fonts/ShellBold.eot');
  src: url('/fonts/ShellBold.eot') format('embedded-opentype'),
       url('/fonts/ShellBold.woff2') format('woff2'),
       url('/fonts/ShellBold.woff') format('woff'),
       url('/fonts/ShellBold.ttf') format('truetype'),
       url('/fonts/ShellBold.svg#ShellBold') format('svg');
}

// html,
// #root{
//   height: 100%;
// }



/*
@import 'variables.scss';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

* {
  -webkit-overflow-scrolling: touch;
}

html,
body,
#root {
  background-color: $body-bg;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.iconWithBackground {
  background: white;
  border-radius: 50%;
}

// Loader and Messenger
.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 4px;
  width: 100%;
  overflow: hidden;
  background-color: #ddd;
  z-index: -1;
}
.loader:before {
  display: block;
  position: absolute;
  content: '';
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: $protag-yellow;
  animation: loading 2s ease-in-out infinite;
}

.spinner-wrapper {
  position: absolute;
  width: 100%;
  height: 84%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner-loader {
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid $protag-yellow;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

.bold {
  font-weight: bold;
}

#messenger {
  width: 100%;
  min-height: 40px;
  position: fixed;
  bottom: -100px;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 250ms ease-in-out;
}

.loaderContainer {
  min-height: 80vh;
  display: flex;
}

#messenger-inner {
  width: auto;
  padding: 8px 24px;
  background: #424242;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  border-radius: 4px 4px 0 0;
}

//Custom helpers missing from B4
.hidden-only-mobile {
  display: block !important;
  @media (max-width: 737px) {
    display: none  !important;
  }
}
.visible-only-mobile {
  display: none   !important;
  @media (max-width: 737px) {
    display: block  !important;
  }
}
.hidden-only-mobile-inline {
  display: inline  !important;
  @media (max-width: 737px) {
    display: none  !important;
  }
}
.visible-only-mobile-inline {
  display: inline  !important;
  @media (max-width: 737px) {
    display: block  !important;
  }
}
#style-6::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: $protag-dark1;
}
#style-6::-webkit-scrollbar{
  width: 10px;
  background-color:#F5F5F5;
}
#style-6::-webkit-scrollbar-thumb{
  background-color:#F8CC11;
  border-radius: 10px;
  //background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%,transparent 25%,transparent 50%,rgba(255, 255, 255, .2) 50%,rgba(255, 255, 255, .2) 75%,transparent 75%,transparent)
}
*/
